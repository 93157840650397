<template>
  <div class="exam-layout full-width full-height">
    <main class="exam-information items-center justify-around q-px-sm">
      <div class="title flex row full-width justify-center">
        <q-btn
          dense
          flat
          icon="chevron_left"
          :to="{
            name: 'training-page',
            query: {
              courseId: courseId,
              trainingId: trainingId,
              postId: postId,
            },
          }"
        />
        <div class="icon-exam q-mr-sm">
          <BadgeType iconBadge="book" typeBadge="Exam" />
        </div>
        <h4>{{ game.title }}</h4>
      </div>
      <iframe
        :src="game.linkGame"
        name="course"
        frameborder="0"
        seamless="seamless"
        sandbox="allow-scripts allow-same-origin"
        width="100%"
        height="100%"
      ></iframe>

      <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </main>
  </div>
</template>

<script>
import TrailDataServices from "@/services/TrailDataServices";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import { ENV  } from "@/utils/env";
import { useQuasar } from "quasar";
import { ref, onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Exam",
  components: {
    DefaultBtn,
  },
  setup() {
    const $q = useQuasar();
    let _router = new useRouter();
    let _route = new useRoute();
    let _trailDataServices = new TrailDataServices();
    let _gameId = _route.query.gameId;
    let _urlAwsBucket =ENV.URL_AWS_BUCKET;

    let courseId = _route.query.courseId;
    let trainingId = _route.query.trainingId;
    let postId = _route.query.postId;
    let loading = ref(false);
    let game = reactive({
      title: "",
      linkGame: "",
    });

    async function getGame() {
      await _trailDataServices
        .getGame(courseId, trainingId, _gameId)
        .then((response) => {
          game.title = response.data.title;
          game.linkGame = _urlAwsBucket + response.data.linkgame;

          _trailDataServices.checkConcludeGame(courseId, trainingId, _gameId);
        });
    }

    onMounted(() => {
      if (_gameId == null) {
        _router.push({
          name: "training-page",
          query: {
            courseId: courseId,
            trainingId: trainingId,
            postId: postId,
          },
        });
      }

      getGame();
    });

    return {
      loading,
      courseId,
      trainingId,
      postId,
      game,
    };
  },
};
</script>

<style lang="scss" scoped>
.exam-layout {
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.exam-information {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title,
  .exam-about,
  .exam-orientation {
    display: none;
  }

  @media (min-width: 800px) {
    .title,
    .exam-about,
    .exam-orientation {
      display: flex;
    }
  }
}

.title {
  .icon-exam {
    width: 45px;
    height: 45px;
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
  }
}

.exam-about {
  min-height: 20%;
  border: 1px solid #489b88;
  border-radius: 20px;
  /* padding: 1% 2%; */
  p {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 22px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #686868;
  }
  small {
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 16px;
    color: #00000073;
  }
}

.exam-timer {
  background: #f7f7f7;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  > div {
    min-width: 75px;
    span {
      padding: 0 4%;
    }
  }

  p {
    margin: 0;
  }
  .exam-time {
    padding: 2%;
    background: #fdfdfd;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.37rem;
    line-height: 27px;
    letter-spacing: 0.15rem;
    color: #000000;
  }
}

.exam-orientation {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
    0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 0.87rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #686868;
  }

  .orientation-items {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #686868;

    border-bottom: 1px solid #e5e5e5;
    padding: 2%;
  }
}

.action-btn {
  display: flex;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: center;
  }

  .btn-finish {
    background: #54aa95;
    color: white;
    box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
      0px 0.85px 3px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
  }
  .btn-dropdown {
    display: flex;

    @media (min-width: 800px) {
      display: none;
    }
  }
}

.exam-content {
  flex: 4;
  overflow: auto;
  .questioncontent {
    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    overflow: auto;
    background: #f7f7f7;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 20px;

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 16px;
      color: #000000;
    }

    p {
      margin: 2% 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }

    .anexo-btn {
      margin-bottom: 1%;

      background: #fdfdfd;
      color: #fe5268;
      border: 1px solid #fe5268;
      box-sizing: border-box;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
        0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
      border-radius: 50px;
      cursor: pointer;
      @media (min-width: 800px) {
        margin-bottom: 0%;
      }
    }
  }
  .card-question {
    display: flex;
    flex-direction: column;
    /* min-height: 60%; */
  }

  .card-option {
    background: #fdfdfd;
    margin-bottom: 1.5%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
      0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }
  }

  .card-option-selected {
    border: 2px solid #489b88;
    margin-bottom: 1.5%;
    box-sizing: border-box;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #489b88;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
}
</style>
